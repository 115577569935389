import React, { useState, useEffect } from "react";
import $ from "jquery";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import { getTranslatedPath } from "../utils/languageUtils";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import jsonp from "jsonp";
import Cookies from "js-cookie";
import OwlCarousel from "react-owl-carousel";
import HeroBanner from '../components/HeroBanner';
import CookieConsent from "react-cookie-consent";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import HomeFeaturedCard from "../components/HomeFeaturedCard";
import HomeCustomCard from "../components/HomeCustomCard";
import HomeCarouselCard from "../components/HomeCarouselCard";
import HomePopularCard from "../components/HomePopularCard";
import products from "../data/products.json";
import newsList from "../data/news.json";
import heroData from '../data/hero.json';
import LatestNewsCard from "../components/LatestNewsCard";

export default function Home2({ setHeader }) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const newsArray = Object.values(newsList);
  const numberOfNewsToDisplay = 4;
  const [viewScrollUp, setViewScrollUp] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [loading, setLoading] = useState(false);
  const [sub, setSub] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [show, setShow] = useState(false);

  const banners = Object.values(heroData).slice(0, 2); // Use to set number of banners

  const handleClose = () => {
    setShow(false);
    Cookies.set("modalClosed", true, { expires: 10 });
  };

  useEffect(() => {
    const cookieExists = Cookies.get("modalClosed");
    const newsletterSubscribed = Cookies.get("newsletterSubscribed");

    if (cookieExists || newsletterSubscribed) {
      setShow(false);
    } else {
      const modalTimeout = setTimeout(() => {
        setShow(true);
      }, 4000);

      return () => clearTimeout(modalTimeout);
    }
  }, []);

  function isValidEmail(email) {
    // Use a regular expression or any other validation logic to validate email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate email
    if (!email.trim()) {
      setLoading(false);
      setEmailError(t("errors.email"));
    } else if (!isValidEmail(email)) {
      setLoading(false);
      setEmailError(t("errors.emailInvalid"));
    } else {
      const url =
        "https://gmail.us13.list-manage.com/subscribe/post-json?u=f5e02948469133d4eb19aa26c&amp;id=8d67ad26d9&amp;f_id=0080e6e2f0";
      jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_, { msg }) => {
        setLoading(false);
        setSub(true);
        Cookies.set("newsletterSubscribed", true, { expires: 60 });
        const timeoutId = setTimeout(() => {
          setShow(false);
        }, 3000);
        return () => clearTimeout(timeoutId);
      });
    }
  };

  useEffect(() => {
    setEmailError(""); // Clear the error message when the language changes
  }, [i18n.language]);

  useEffect(() => {
    if (sub) {
      const timeoutId = setTimeout(() => {
        setSub(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [sub]);

  const handleChange = (e) => {
    setEmail(e.target.value);
    setEmailError(""); // Clear the error message when the email is modified
  };

  const [isMobile, setIsMobile] = useState(false);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setViewScrollUp(true);
      } else {
        setViewScrollUp(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    // Function to check screen size
    const checkScreenSize = () => {
      if (window.innerWidth <= 767) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Check on initial render
    checkScreenSize();

    // Add event listener to handle screen resize
    window.addEventListener("resize", checkScreenSize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setHeader(true);
      } else {
        setHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [setHeader]);

  const translatedPaths = getTranslatedPath(i18n.language);

  return (
    <>
      <Helmet>
        {/* meta tag  */}
        <meta charset="utf-8" />
        <title>
          {t("header.home.pageTitle")} DURŌ Niche perfumery - Niche parfimerija
        </title>

        {/* <!-- Meta Tags--> */}
        <meta name="description" content={t("header.home.metaDescription")} />
        <meta
          name="keywords"
          content="durō, duro, niche perfumery, niche parfimerija,  parfimerija, galerija"
        />
        <meta name="author" content="intellect.se" />

        {/* <!-- responsive tag --> */}
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* <!-- Facebook Metadata --> */}
        <meta property="og:image:height" content="1200" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:title" content="DURŌ | Niche perfumery" />
        <meta
          property="og:description"
          content="DURŌ, where the art of niche perfumery meets ultimate luxury. We represent 25 of the most sought-after artisan perfume houses and offer a one-of-a-kind immersive experience in our boutique."
        />
        <meta property="og:url" content="https://www.parfimerija-duro.me" />
        <meta
          property="og:image"
          content="https://www.parfimerija-duro.me/assets/images/favicon/og-image.jpg"
        />
      </Helmet>
      <Loader />
      {/* // Main content Start  */}
      <div className="main-content main-content-overflow lion-bg">
        <OwlCarousel
          className="rs-banner nav-style1 rs-carousel owl-carousel"
          loop={true}
          items={2}
          margin={0}
          autoplay={true}
          autoplayHoverPause={true}
          autoplayTimeout={5000}
          smartSpeed={800}
          dots={true}
          nav={true}
          navSpeed={false}
          center={false}
          navText={[
            "<i class='fa fa-chevron-left fa-3x'> </i>",
            "<i class='fa fa-chevron-right fa-3x'> </i>",
          ]}
          responsive={{
            0: {
              items: 1,
              nav: false,
              dots: true,
              center: false,
            },
            768: {
              items: 1,
              nav: true,
              dots: false,
              center: false,
            },
            992: {
              items: 1,
              nav: false,
              dots: true,
              center: false,
            },
            1200: {
              items: 1,
              nav: true,
              dots: false,
              center: false,
            },
          }}
        >
        {banners.map((banner, index) => (
          <HeroBanner key={index} bannerData={banner} />
        ))}
        </OwlCarousel>

        <section class="container pt-30">
          <div class="row">
            <div class="col-lg-5 popular">
              <div className="sec-title">
                <h2 className="title pb-22">{t("home.popular.title")}</h2>
                <div
                  className={`heading-border-line mb-40 ${
                    isMobile ? "" : "left-style"
                  }`}
                ></div>
              </div>
              <div class="row">
                {products.focus.map((product, index) => (
                  <HomePopularCard
                    key={index}
                    title={product.title}
                    badgeText={product.badgeText}
                    image={product.image}
                    href={product.href}
                    alt={product.alt}
                    iconClass={product.iconClass}
                    cardClass={product.cardClass}
                  />
                ))}
              </div>
            </div>
            <div class="col-lg-7 in-focus">
              <div className="sec-title">
                <h2 className="title pb-22">{t("home.brandInFocus.title")}</h2>
                <div
                  className={`heading-border-line mb-40 ${
                    isMobile ? "" : "left-style"
                  }`}
                ></div>
              </div>
              <div class="row">
                {products.brand.map((product, index) => (
                  <HomePopularCard
                    key={index}
                    title={product.title}
                    badgeText={product.badgeText}
                    image={product.image}
                    href={product.href}
                    alt={product.alt}
                    iconClass={product.iconClass}
                    cardClass={product.cardClass}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>

        <section class="container pt-30">
          <div class="col-lg-12 popular">
            <div className="sec-title text-center">
              <span className="sub-text">
                {t("home.featuredProducts.subText")}
              </span>
              <h2 className="title pb-22">
                {t("home.featuredProducts.title")}
              </h2>
              <div class="heading-border-line mb-40"></div>
            </div>
          </div>
          <div class="col-lg-12 ">
            <div class="row">
              <ul className="product-wrapper">
                {products.featured.map((product, index) => (
                  <HomeFeaturedCard
                    key={index}
                    image={product.image}
                    collection={product.collection}
                    title={product.title}
                    description={product.description}
                    price={product.price}
                    href={product.href}
                  />
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section class="container py-3">
          <div class="col-lg-12 popular">
            <div className="sec-title text-center">
              <span className="sub-text">
                {t("home.ourRecommendations.subText")}
              </span>
              <h2 className="title pb-22">
                {t("home.ourRecommendations.title")}
              </h2>
              <div class="heading-border-line mb-40"></div>
            </div>
          </div>
          <div class="col-lg-12 ">
            <div class="row">
              <ul className="product-wrapper">
                {products.spotlight.map((product, index) => (
                  <HomeFeaturedCard
                    key={index}
                    image={product.image}
                    collection={product.collection}
                    title={product.title}
                    description={product.description}
                    price={product.price}
                    href={product.href}
                  />
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section class="container py-3">
          <div class="col-lg-12 carousel">
            <div class="row">
              <OwlCarousel
                className="rs-carousel owl-carousel"
                loop={true}
                items={7}
                margin={30}
                autoplay={true}
                autoplayHoverPause={true}
                autoplayTimeout={5000}
                smartSpeed={800}
                dots={true}
                nav={false}
                navSpeed={false}
                center={false}
                navText={[
                  "<i class='flaticon-left-arrow'></i>",
                  "<i class='flaticon-right-arrow'></i>",
                ]}
                responsive={{
                  0: {
                    items: 1,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  768: {
                    items: 1,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  992: {
                    items: 1,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  1200: {
                    items: 1,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                }}
              >
                {products.carousel.map((product, index) => (
                  <HomeCarouselCard
                    key={index}
                    image={product.image}
                    href={product.href}
                    alt={product.alt}
                    iconClass={product.iconClass}
                  />
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section class="container py-3">
          <div class="col-lg-12 popular">
            <div className="sec-title text-center">
              <span className="sub-text">
                {t("home.newProducts.subText")}
              </span>
              <h2 className="title pb-22">
                {t("home.newProducts.title")}
              </h2>
              <div class="heading-border-line mb-40"></div>
            </div>
          </div>
          <div class="col-lg-12 ">
            <div class="row">
              <ul className="product-wrapper">
                {products.novelties.map((product, index) => (
                  <HomeFeaturedCard
                    key={index}
                    image={product.image}
                    collection={product.collection}
                    title={product.title}
                    description={product.description}
                    price={product.price}
                    badge={product.badge}
                    href={product.href}
                  />
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section class="container py-4">
          <div class="col-lg-12 popular">
            <div className="sec-title text-center">
              <span className="sub-text">
                {t("home.ourRecommendations.subText")}
              </span>
              <h2 className="title pb-22">
                {t("home.ourRecommendations.title")}
              </h2>
              <div class="heading-border-line mb-40"></div>
            </div>
          </div>
          <div class="col-lg-12 ">
            <div className="row custom-cards">
              <div className="container mt-5">
                <div className="row g-4">
                  {products.customCards.map((product, index) => (
                    <HomeCustomCard
                      key={index}
                      image={product.image}
                      href={product.href}
                      alt={product.alt}
                      badge={product.badge}
                      badgeClass={product.badgeClass}
                      overlayText={product.overlayText[currentLanguage]}
                      customClass={product.customClass}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="container py-4 mt--160">
          <div
            id="rs-about"
            className="rs-about story style height-ctrl md-pb-30 md-pt-30 col-lg-12"
          >
            <div className="row y-middle">
              <div className="col-lg-6 md-mb-70">
                <div className="about-img">
                  <img
                    src="/assets/images/home/team-0924-[about-2x].jpg"
                    alt="Our Story"
                  />
                </div>
              </div>
              <div className="col-lg-6 pl-50 md-pl-15">
                <div className="sec-title">
                  <h2 className="title title2 title3 pb-30">
                    {t("home.story.title")}
                  </h2>
                  <p className="desc-small pb-25">{t("home.story.desc1")} </p>
                  <p className="desc-small">{t("home.story.desc2")}</p>
                  <div className="btn-part mt-40">
                    <Link
                      className="readon btn-text ticket"
                      to={translatedPaths["our-story"]}
                    >
                      <span>{t("home.explore")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="container">
          <div
            id="rs-about"
            className="rs-about style height-ctrl md-pb-40 md-mt-10 mt-30"
          >
            <div className="row y-middle">
            <div className="col-lg-6 md-mb-70 md-mt-40 order-1 order-lg-2">
                <div className="about-img">
                  <img
                    src="/assets/images/home/boutique-0924-[about-2x].jpg"
                    alt="Our Perfumery"
                  />
                </div>
              </div>
              <div className="col-lg-6 pl-50 md-pl-15 order-2 order-lg-1">
                <div className="sec-title">
                  <h2 className="title title2 title3 pb-30">
                    {t("home.boutique.title")}
                  </h2>
                  <p className="desc-small pb-25">
                    {t("home.boutique.desc1")}
                  </p>
                  <div className="btn-part mt-10">
                    <Link
                      className="readon btn-text ticket"
                      to={translatedPaths["perfumeries"]}
                    >
                      <span>{t("home.read")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="container py-4">
          <div
            id="rs-blog"
            className="rs-blog blog-main-home home3-blog-style height-ctrl md-pb-40 md-pt-40 mt-40 md-mt-10"
          >
            <div className="row">
              <div className="sec-title text-center mb-60 md-mb-40">
                <h2 className="title title2 title3 text-middle">
                  {t("home.news.title")}
                </h2>
              </div>
              <OwlCarousel
                className="rs-carousel owl-carousel"
                loop={true}
                items={3}
                margin={30}
                autoplay={true}
                autoplayHoverPause={true}
                autoplayTimeout={5000}
                smartSpeed={800}
                dots={true}
                nav={false}
                navSpeed={false}
                center={false}
                navText={[
                  "<i class='flaticon-left-arrow'></i>",
                  "<i class='flaticon-right-arrow'></i>",
                ]}
                responsive={{
                  0: {
                    items: 1,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  768: {
                    items: 2,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  992: {
                    items: 2,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  1200: {
                    items: 3,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                }}
              >
                {newsArray.slice(0, numberOfNewsToDisplay).map((news) => (
                  <LatestNewsCard key={news.title} news={news} />
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>

        <section className="container py-4">
          <div className="rs-services style1 bg1">
            <div className="container">
              <div className="row y-middle">
                <div className="col-lg-4 col-md-6 md-mb-30">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <i class="fa fa-truck-fast"></i>
                      </div>
                      <div className="services-text">
                        <h4 className="title">{t("home.shipping.title")}</h4>
                        <p className="services-txt">
                          {t("home.shipping.description")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 md-mb-30">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <i class="fa fa-money-bill-transfer"></i>
                      </div>
                      <div className="services-text">
                        <h4 className="title"> {t("home.moneyBack.title")}</h4>
                        <p className="services-txt">
                          {t("home.moneyBack.description")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <i class="fa fa-gift"></i>
                      </div>
                      <div className="services-text">
                        <h4 className="title">
                          {t("home.surpriseGift.title")}
                        </h4>
                        <p className="services-txt">
                          {t("home.surpriseGift.description")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- start scrollUp  --> */}
        <div
          id="scrollUp"
          className={viewScrollUp ? "orange-color show" : "orange-color"}
          onClick={() =>
            $("html,body").animate(
              {
                scrollTop: 0,
              },
              500
            )
          }
        >
          <i className="fa fa-angle-up"></i>
        </div>
        {/* <!-- End scrollUp  --> */}
        <Modal
          show={show}
          onHide={handleClose}
          centered
          backdrop="static"
          className="subscribe-modal"
        >
          <Modal.Body className="p-0">
            <div className="p-0 modal__content">
              <div className="col-5 px-0 d-none d-md-block">
                <img
                  src="/assets/images/modal/main.jpg"
                  alt="niche perfumery"
                  className="modal__img"
                />
              </div>
              <div className="col-md-7 modal__text-content py-4 px-md-5 px-4">
                <div className="modal__close-btn" onClick={handleClose}>
                  <div className="close-container">
                    <div className="leftright"></div>
                    <div className="rightleft"></div>
                    {/* <label className="close-label">close</label> */}
                  </div>
                </div>
                <h3 className="modal__heading mt-4">
                  {t("modal.subscribe.title")}
                </h3>
                <div className="modal__description">
                  {t("modal.subscribe.desc")}
                </div>
                <div className="modal_form">
                  <div className="newsletter-wrap">
                    <form
                      className="newsletter-form"
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <input
                        type="email"
                        name="email"
                        placeholder={t("yourEmail")}
                        required=""
                        value={email}
                        onChange={handleChange}
                      />
                      {emailError && (
                        <div className="error-message">* {emailError}</div>
                      )}
                      <em className="paper-plane">
                        <input type="submit" value="Subscribe" />
                      </em>
                    </form>
                    <div className="col-6"></div>
                    {loading ? (
                      <div className="col-6 d-flex justify-content-start align-items-center">
                        <div
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <p className="mb-0">{t("errors.submitting")}</p>
                      </div>
                    ) : sub ? (
                      <div className="d-flex justify-content-start align-items-center">
                        <i className="fa fa-check"></i>
                        <p className="mb-0">{t("errors.thanks")}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="modal__privacy">
                  {t("modal.subscribe.notice")}{" "}
                  <Link to={translatedPaths["privacy"]}>
                    <span>{t("modal.subscribe.policy")} »</span>
                  </Link>
                </div>
                <div className="modal__cancel mt-4">
                  <a href="#!" onClick={handleClose}>
                    {t("modal.subscribe.decline")}
                  </a>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {/* // Main content End  */}
      <CookieConsent
        // debug
        buttonText={t("modal.cookies.accept")}
        buttonClasses="accept-cookies-btn"
        containerClasses="cookie-consent-container"
        style={{
          background: "#ffffff",
          alignItems: "center",
          gap: "1em",
          zIndex: "10000",
          color: "#020f2f",
        }}
      >
        <p className="m-0">{t("modal.cookies.consent")}</p>
        <p className="m-0">
          {t("modal.cookies.notice")}{" "}
          <Link className="cookie-policy-btn" to={translatedPaths["privacy"]}>
            <span>{t("modal.cookies.policy")} »</span>
          </Link>
        </p>
      </CookieConsent>
    </>
  );
}