import React from 'react';

function HomeCarouselCard({ image, href, alt, iconClass }) {
  return (
    <div className="brand-card">
      <a href={href} className="card-link">
        <div className="image-wrapper">
          <img src={image} alt={alt} className="product-image" />
          <div className="overlay">
            <i className={iconClass + " cart-icon"}></i>
          </div>
        </div>
      </a>
    </div>
  );
}

export default HomeCarouselCard;