import React from 'react';

function HomeCustomCard({ image, href, alt, badge, badgeClass, overlayText, customClass }) {
  return (
    <div className={`col-md-6 ${customClass}`}>
      <a href={href} className="card-link">
        <div className={`card-custom ${customClass}`}>
          <img src={image} alt={alt} />
          <div className={`badge-custom ${badgeClass}`}>{badge}</div>
          <div className="overlay-text">{overlayText}</div>
        </div>
      </a>
    </div>
  );
}

export default HomeCustomCard;