import React from 'react';
import { useTranslation } from 'react-i18next';

const HeroBanner = ({ bannerData }) => {
  const { i18n } = useTranslation(); // Get the i18n instance
  const currentLanguage = i18n.language; // Determine the current language ('en', 'me', etc.)

  return (
    <a
      href={bannerData.link}
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        minHeight: '100vh',
        overflow: 'hidden',
        display: 'block',
      }}
    >
      <div
        className={`rs-banner ${bannerData.className} banner-home-style3`}
      >
        <div className="container">
          <div className="content-wrap">
            <span className="sub-title">
              {bannerData.smallTitleKey[currentLanguage]}
            </span>
            <h1 className="title">
              {bannerData.titleKey[currentLanguage]}
            </h1>
            <h2 className="title-small">
              {bannerData.subTitleKey[currentLanguage]}
            </h2>
          </div>
        </div>
      </div>
    </a>
  );
};

export default HeroBanner;
