import { Suspense, useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MedusaProvider } from "medusa-react";
import { QueryClient } from "@tanstack/react-query";
import Header2 from "./components/Header2";
import Header1 from "./components/Header1";
import Home from "./pages/Home";
import Home2 from "./pages/Home2";
import Story from "./pages/Story";
import Footer2 from "./components/Footer2";
import Brands from "./pages/Brands";
import Press from "./pages/Press";
import Contact from "./pages/Contact";
import Perfumery from "./pages/Perfumery";
import Locations from "./pages/Locations";
import Gallery from "./pages/Gallery";
import News from "./pages/News";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Giveaway from "./pages/Giveaway";
import Brand from "./pages/Brand";
import Products from "./pages/Products";
import GoToTop from "./components/GoToTop";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import { Modal } from "react-bootstrap";
import ContactForm from "./components/ContactForm";
import { paths } from "./utils/languageUtils";
import newsData from "./data/news.json";
import AllProducts from "./pages/AllProducts";
import SearchComponent from "./components/SearchComponent";
function App() {
  const queryClient = new QueryClient();
  const medusaUrl = "https://admin.parfimerija-duro.me";
  const [header, setHeader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const changeNavbar = location.pathname === `/${i18n.language}`;
  const changeNavbar2 = location.pathname === `/${i18n.language}/`;

  const [active, setActive] = useState("");
  const baseRouteUrl = `/${i18n.language}`;

  // useEffect(() => {
  //   const currentPath = location.pathname;

  //   // Define the old URLs and their corresponding new URLs
  //   const redirectionMap = {
  //     "/": `/${i18n.language}`,
  //     "/sr/our-story": "/sr/o-nama",
  //     // Add more redirections as needed for other old URLs
  //   };

  //   // Check if the current path needs redirection
  //   if (redirectionMap.hasOwnProperty(currentPath)) {
  //     const newPath = redirectionMap[currentPath];
  //     navigate(newPath, { replace: true });
  //   }
  // }, [location.pathname, i18n.language, navigate]);

  // Create a reverse mapping
  const reversePathTranslations = {};
  for (const key in paths) {
    const value = paths[key];
    reversePathTranslations[value] = key;
  }

  const handleLanguageChange = () => {
    const newLanguage = i18n.language === "me" ? "en" : "me";
    let newPath = location.pathname.replace(
      new RegExp(`^\\/(en|me)(\\/)?`),
      `/${newLanguage}/`
    );

    if (newPath !== "/" && newPath.endsWith("/")) {
      newPath = newPath.slice(0, -1);
    } else {
      // Check if the current path has a translation
      const currentPathWithoutLanguage = newPath.replace(
        `/${newLanguage}/`,
        ""
      );

      const pathSegments = currentPathWithoutLanguage.split("/");
      const updatedSegments = pathSegments.map((segment) => {
        switch (segment) {
          case "brands":
            return "brendovi";
          case "brendovi":
            return "brands";
          case "products":
            return "proizvodi";
          case "proizvodi":
            return "products";
          case "perfumeries":
            return "parfimerije";
          case "parfimerije":
            return "perfumeries";
          default:
            return segment;
        }
      });
      const updatedPath = updatedSegments.join("/");

      const arr = currentPathWithoutLanguage.split("/");

      if (updatedPath !== currentPathWithoutLanguage) {
        newPath = `/${newLanguage}/${updatedPath}`;
      } else if (
        (arr[0] === "vijesti" || arr[0] === "news") &&
        arr.length === 2
      ) {
        const newsArray = Object.values(newsData);
        const matchedNews =
          newsArray.find(
            (news) => news.path === arr[1] || news.me_path === arr[1]
          ) || null;

        newPath =
          newLanguage === "en"
            ? `en/news/${matchedNews.path}`
            : matchedNews?.me_path
            ? `me/vijesti/${matchedNews.me_path}`
            : `me/vijesti/${matchedNews.path}`;
      } else {
        const translatedPath = paths[currentPathWithoutLanguage];

        // Update newPath accordingly
        if (translatedPath) {
          newPath = `/${newLanguage}/${translatedPath}`;
        } else {
          // Check if the translated path has a reverse mapping
          const originalPath =
            reversePathTranslations[currentPathWithoutLanguage];
          if (originalPath) {
            newPath = `/${newLanguage}/${originalPath}`;
          }
        }
      }
    }

    navigate(newPath);
  };

  useEffect(() => {
    const languageFromUrl = location.pathname.split("/")[1];
    if (languageFromUrl !== i18n.language) {
      const newLanguage = languageFromUrl === "me" ? "me" : "en";
      i18n.changeLanguage(newLanguage).then(() => {
        let newPath = location.pathname.replace(
          new RegExp(`^\\/(en|me)(\\/)?`),
          `/${newLanguage}/`
        );

        if (newPath !== "/" && newPath.endsWith("/")) {
          newPath = newPath.slice(0, -1);
        }

        navigate(newPath, { replace: true });
      });
    }
  }, [i18n.language, location.pathname, i18n, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("showModal") === "true") {
      setShowModal(true);
    }
  }, [location.search]);

  const closeModal = () => {
    setShowModal(false);
  };

  const [isVisible, setIsVisible] = useState(false);

  return (
    <Suspense fallback="Loading...">
      <div className="position-absolute" style={{zIndex: 1000}}>
      <SearchComponent isVisible={isVisible} setIsVisible={setIsVisible}/>
      </div>
      <MedusaProvider
        baseUrl={medusaUrl}
        queryClientProviderProps={{ client: queryClient }}
      >
        <div>
          {changeNavbar || changeNavbar2 ? (
            <Header1
              header={header}
              handleLanguageChange={handleLanguageChange}
              active={active}
              setActive={setActive}
              setIsVisible={setIsVisible}
            />
          ) : (
            <Header2
              header={header}
              handleLanguageChange={handleLanguageChange}
              active={active}
              setActive={setActive}
              setIsVisible={setIsVisible}
            />
          )}
          <Routes>
            <Route
              path={baseRouteUrl}
              element={<Home2 setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
                baseRouteUrl === "/en" ? "/index" : "/index"
              }`}
              element={<Home />}
            />
            <Route
              path={`${baseRouteUrl}${
                baseRouteUrl === "/en" ? "/our-story" : "/o-nama"
              }`}
              element={<Story setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
                baseRouteUrl === "/en" ? "/contact" : "/kontakt"
              }`}
              element={<Contact setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
                baseRouteUrl === "/en" ? "/perfumeries" : "/parfimerije"
              }`}
              element={<Locations setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
              baseRouteUrl === "/en" ? "/perfumeries/:slug" : "/parfimerije/:slug"
              }`}
              element={<Perfumery setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
              baseRouteUrl === "/en" ? "/gallery/:slug" : "/galerija/:slug"
              }`}
              element={<Gallery setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
              baseRouteUrl === "/en" ? "/news/:slug" : "/vijesti/:slug"
              }`}
              element={<News setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
              baseRouteUrl === "/en" ? "/news" : "/vijesti"
              }`}
              element={<Press setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
                baseRouteUrl === "/en" ? "/brands/:slug" : "/brendovi/:slug"
              }`}
              element={<Brand setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
                baseRouteUrl === "/en" ? "/brands" : "/brendovi"
              }`}
              element={<Brands setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
                baseRouteUrl === "/en"
                  ? "/brands/:slug/products"
                  : "/brendovi/:slug/proizvodi"
              }`}
              element={<Products setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
                baseRouteUrl === "/en" ? "/products" : "/proizvodi"
              }`}
              element={<AllProducts setHeader={setHeader} />}
            />

            <Route
              path={`${baseRouteUrl}${
                baseRouteUrl === "/en"
                  ? "/privacy-policy"
                  : "/politika-privatnosti"
              }`}
              element={<Privacy setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
                baseRouteUrl === "/en"
                  ? "/terms-of-use"
                  : "/opsti-uslovi-kupovine"
              }`}
              element={<Terms setHeader={setHeader} />}
            />
            <Route
              path={`${baseRouteUrl}${
                baseRouteUrl === "/en" ? "/giveaway" : "/nagradna-igra"
              }`}
              element={<Giveaway setHeader={setHeader} />}
            />
            <Route path="*" element={<Error404 />} />
            <Route path="/error" element={<Error500 />} />
          </Routes>
          {changeNavbar || changeNavbar2 ? null : <GoToTop />}
          {<Footer2 />}
        </div>
        <Modal
          show={showModal}
          onHide={closeModal}
          centered
          backdrop="static"
          className="subscribe-modal"
        >
          <Modal.Body className="py-5 px-3 gray-bg-4">
            <div className="p-0 modal__content gray-bg-4">
              <div className="modal__close-btn" onClick={closeModal}>
                <div className="close-container">
                  <div className="leftright"></div>
                  <div className="rightleft"></div>
                  {/* <label className="close-label">close</label> */}
                </div>
              </div>
              <div className="rs-contact home-style1 home-style3 contact-home-style4 height-ctrl md-pb-50 md-pt-40">
                <div className="container">
                  {/* <div className="sec-title text-center mb-60 md-mb-40">
                <h2 className="title title2 title3 text-middle">
                  {t("home.contact.contact")}
                </h2>
              </div> */}
                  {/* <!-- Contact Section Start --> */}
                  <div className="rs-contact">
                    <div className="row y-middle">
                      <div className="col-lg-6 pr-40 md-pr-15 md-mb-50">
                        <div className="contact-map">
                          <iframe
                            title="Location"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11323.815743977078!2d20.4463451!3d44.8021274!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a71158f3b75d9%3A0x3cdfdea1cf3307be!2sDUR%C5%8C%20%7C%20Niche%20perfumery!5e0!3m2!1sen!2srs!4v1681389080409!5m2!1sen!2srs"
                          ></iframe>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contact-wrap">
                          <div id="form-messages"></div>
                          <ContactForm />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Contact Section End --> */}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </MedusaProvider>
    </Suspense>
  );
}

export default App;
