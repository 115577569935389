import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function NewsCard({ news }) {
  const { t, i18n } = useTranslation();

  const path = i18n?.language === "en" ? news.path : news?.me_path || news.path;

  return (
    <div className="col-lg-12 mb-50">
      <div className="blog-item">
        <div className="blog-img">
          <Link to={path}>
            <img src={news.img} alt={news.url} />
          </Link>
          <ul className="post-categories">
            <li>
              <Link to={path}>{t("home.news.news")}</Link>
            </li>
          </ul>
        </div>
        <div className="blog-content">
          <h3 className="blog-title">
            <Link to={path}>{t(news.title)}</Link>
          </h3>
          <div className="blog-meta">
            <ul className="btm-cate">
              <li>
                <div className="blog-date">
                  <i className="fa fa-calendar-check-o"></i> {news.date}
                </div>
              </li>
              <li>
                <div className="author">
                  <i className="fa fa-user-o"></i>
                  {t(news.admin)}
                </div>
              </li>
            </ul>
          </div>
          <div className="blog-desc">{t(news.desc)}</div>
          <div className="blog-button">
            <Link to={path}>
              <span className="btn-txt">{t("home.read")}</span>
              <i className="fa fa fa-chevron-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
