import React, { useEffect } from "react";
import $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function GalleryMini() {
  const currentPath = window.location.pathname;
  let folderName = "";
  let length = 0;
  if (currentPath.includes("podgorica")) {
    folderName = "podgorica";
    length = 16;
  } else if (currentPath.includes("porto-montenegro")) {
    folderName = "porto-montenegro";
    length = 12;
  }

  const imageSrcList = Array.from(
    { length: length },
    (_, i) => `/assets/images/gallery/${folderName}/${i + 1}.jpg`
  );

  // useEffect(() => {
  //   $(".image-popup").magnificPopup({
  //     type: "image",
  //     callbacks: {
  //       beforeOpen: function () {
  //         this.st.image.markup = this.st.image.markup.replace(
  //           "mfp-figure",
  //           "mfp-figure animated zoomInDown"
  //         );
  //       },
  //     },
  //     gallery: {
  //       enabled: true,
  //     },
  //   });
  // }, []);

  useEffect(() => {
    let galleryItems = imageSrcList.map(img => {
      return { src: img };
    });
  
    $("a.image-popup").on("click", function(e) {
      e.preventDefault();
      let startIndex = imageSrcList.indexOf($(this).attr("href"));
      $.magnificPopup.open({
        items: galleryItems,
        type: "image",
        gallery: {
          enabled: true
        },
        index: startIndex,
        callbacks: {
          beforeOpen: function() {
            this.st.image.markup = this.st.image.markup.replace(
              "mfp-figure",
              "mfp-figure animated zoomInDown"
            );
          }
        }
      });
    });
  
    return () => {
      // Destruct MagnificPopup when the component is unmounted.
      $.magnificPopup.close();
      $("a.image-popup").off("click");
    };
  }, [imageSrcList]);
  
  
  

  return (
    <div className="row">
      <OwlCarousel
        className="rs-carousel owl-carousel"
        loop={true}
        items={3}
        margin={30}
        autoplay={true}
        autoplayHoverPause={true}
        autoplayTimeout={5000}
        smartSpeed={800}
        dots={true}
        nav={false}
        navSpeed={false}
        center={false}
        navText={[
          "<i className='flaticon-left-arrow'></i>",
          "<i className='flaticon-right-arrow'></i>",
        ]}
        responsive={{
          0: {
            items: 1,
            nav: false,
            dots: true,
            center: false,
          },
          768: {
            items: 2,
            nav: false,
            dots: true,
            center: false,
          },
          992: {
            items: 2,
            nav: false,
            dots: true,
            center: false,
          },
          1200: {
            items: 3,
            nav: false,
            dots: true,
            center: false,
          },
        }}
      >
        {imageSrcList.map((img) => (
          <div className="">
            <div className="gallery-item">
              <div className="gallery-icon">
                <a className="image-popup" href={img}>
                  <img src={img} alt="" />
                </a>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
}
