export const paths = {
  "our-story": "o-nama",
  "gallery": "galerija",
  "giveaway": "nagradna-igra",
  "brands": "brendovi",
  "news": "vijesti",
  "contact": "kontakt",
  "perfumeries": "parfimerije",
  "privacy-policy": "politika-privatnosti",
  "terms-of-use": "opsti-uslovi-kupovine",
  "products": "proizvodi"
};

export const getTranslatedPath = (language) => {
  return Object.keys(paths).reduce((result, key) => {
    result[key] = `/${language}/${language === "en" ? key : paths[key]}`;
    return result;
  }, {});
};
