import React, { useEffect, useState } from "react";
import $ from "jquery";
// import magnificPopup from 'magnific-popup';
import "magnific-popup";
import { Swiper, SwiperSlide } from "swiper/react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Mousewheel } from "swiper";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ContactForm from "./ContactForm";
//import Testimonials from "./Testimonials";
import brandsList from "../data/brands.json";
import BrandsCard from "./BrandsCard";
import newsList from "../data/news.json";
import LatestNewsCard from "./LatestNewsCard";
import { getTranslatedPath } from "../utils/languageUtils";

const SwiperDesktop = () => {
  const [render, setRender] = useState(0);
  const { t, i18n } = useTranslation();
  const brandsArray = Object.values(brandsList);
  const newsArray = Object.values(newsList);

  useEffect(() => {
    setRender((prevRender) => prevRender + 0.001);
  }, [i18n.language]);

  useEffect(() => {
    $(".popup-videos, .popup-border").magnificPopup({
      disableOn: 10,
      type: "iframe",
      title: "popup",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });
  }, []);

  const translatedPaths = getTranslatedPath(i18n.language);

  return (
    <div className="swiper-container">
      <Swiper
        direction={"vertical"}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        }}
        slidesPerView={1}
        spaceBetween={0}
        speed={700}
        mousewheel={true}
        modules={[Pagination, Mousewheel]}
        className="swiper-wrapper"
      >
        <SwiperSlide className="swiper-slide">
          {/* <!-- Banner Section Start --> */}
          {/*<div className="rs-banner banner-home-style3 height-ctrl">
            <div className="container">
              <div className="content-wrap">
                <span className="sub-title">
                  Podgorica &bull; Porto Montenegro
                </span>
                <h1 className="title">{t("home.title")}</h1>
                <h2 className="title-small">{t("home.titleSmall")}</h2>
                <ul className="banner-bottom">
                  {/*<li>
                    <Link
                        className="readon btn-text ticket text-right"
                        to={`/${i18n.language}/news/black-friday`}
                      >
                        <span>{t("home.read")}</span>
                      </Link>
                  </li> 
                  <li>
                    <div className="rs-videos">
                      <div className="animate-border white-color-style">
                        <a
                          className="popup-border"
                          href="https://www.youtube.com/watch?v=rZhg7Y2CCpY"
                        >
                          <i className="fa fa-play"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>*/}
          <div className="rs-banner banner-home-style3 height-ctrl">
            <div className="container">
              <div className="content-wrap">
                <span className="sub-title">
                  MEMO PARIS
                </span>
                <h1 className="title">KOTOR</h1>
                <h2 className="title-small">{t("home.titleSmallKotor")}</h2>
                <ul className="banner-bottom">
                  <li>
                    <Link
                        className="readon btn-text ticket text-right"
                        to={`/${i18n.language}/${
                          i18n.language === "en" ? "brands" : "brendovi"
                        }/memo`}
                      >
                        <span>{t("home.discover")}</span>
                      </Link>
                  </li> 
                  {/*<li>
                    <div className="rs-videos">
                      <div className="animate-border white-color-style">
                        <a
                          className="popup-border"
                          href="https://www.youtube.com/watch?v=rZhg7Y2CCpY"
                        >
                          <i className="fa fa-play"></i>
                        </a>
                      </div>
                    </div>
                  </li>*/}
                </ul>
              </div>
            </div>
          </div>
          {/*
          <Link className="hero-link"
            to={`/${i18n.language}/${
              i18n.language === "en" ? "news" : "vijesti"
            }/marc-antoine-barrois-tilia`}
            >      
          <div className="rs-banner banner-home-style3 height-ctrl">
            <video playsinline="playsinline" muted="muted" loop="loop" autoplay="autoplay" preload="metadata" className="video-bg" poster="/assets/images/banner/videos/tilia-thumbnail.jpg">
              <source src="/assets/images/banner/videos/tilia.mp4" type="video/mp4" />              
              Your browser does not support the video tag.
            </video>
            <img src="/assets/images/banner/tilia_2.jpg" alt="Marc Antoine-Barrois Tilia" className="fallback-image" />
            <div className="container">
              <div className="content-wrap"></div>
            </div>
          </div>
          </Link>
          */}
          {/* <!-- Banner Section End --> */}
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          {/* <!-- About Section Start --> */}
          <div
            id="rs-about"
            className="rs-about style height-ctrl md-pb-80 md-pt-80"
          >
            <div className="container">
              <div className="row y-middle">
                <div className="col-lg-6 md-mb-70">
                  <div className="about-img">
                    <img
                      src="/assets/images/about/about-9.png"
                      alt="Our Story"
                    />
                  </div>
                </div>
                <div className="col-lg-6 pl-50 md-pl-15">
                  <div className="sec-title">
                    <h2 className="title title2 title3 pb-30">
                      {t("home.story.title")}
                    </h2>
                    <p className="desc-small pb-20">{t("home.story.desc1")} </p>
                    <p className="desc-small pb-20">{t("home.story.desc2")}</p>
                    <p className="desc-small">{t("home.story.desc3")}</p>
                    <div className="btn-part mt-40">
                      <Link
                        className="readon btn-text ticket"
                        to={translatedPaths["our-story"]}
                      >
                        <span>{t("home.explore")}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- About Section End --> */}
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          {/* <!-- Boutique Section Start --> */}
          <div
            id="rs-about"
            className="rs-about style height-ctrl md-pb-80 md-pt-80"
          >
            <div className="container">
              <div className="row y-middle">
                <div className="col-lg-6 pl-50 md-pl-15">
                  <div className="sec-title">
                    <h2 className="title title2 title3 pb-30">
                      {t("home.boutique.title")}
                    </h2>
                    <p className="desc-small pb-25">
                      {t("home.boutique.desc1")}{" "}
                    </p>
                    <p className="desc-small">{t("home.boutique.desc2")}</p>
                    <div className="btn-part mt-40">
                      <Link
                        className="readon btn-text ticket"
                        to={translatedPaths["perfumeries"]}
                      >
                        <span>{t("home.read")}</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 md-mb-70">
                  <div className="about-img">
                    <img
                      src="/assets/images/about/about-7.png"
                      alt="Our Story"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- About Section End --> */}
        </SwiperSlide>

        <SwiperSlide className="swiper-slide">
          {/* <!-- Team Section Start --> */}
          <div className="rs-team style7 height-ctrl md-pb-70 md-pt-70">
            <div className="container">
              <div className="sec-title text-center mb-60">
                <h2 className="title title2 title3 text-middle">
                  {t("home.featured.title")}
                </h2>
              </div>
              <OwlCarousel
                className="rs-carousel owl-carousel"
                loop={true}
                items={3}
                margin={30}
                autoplay={true}
                autoplayHoverPause={true}
                autoplayTimeout={5000}
                smartSpeed={800}
                dots={true}
                nav={false}
                navSpeed={false}
                center={false}
                navText={[
                  "<i class='flaticon-left-arrow'></i>",
                  "<i class='flaticon-right-arrow'></i>",
                ]}
                responsive={{
                  0: {
                    items: 1,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  768: {
                    items: 2,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  992: {
                    items: 2,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  1200: {
                    items: 3,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                }}
              >
                <div className="team-item">
                  <div className="team-inner-wrap">
                    <div className="images-wrap">
                      <img src="/assets/images/products/1.jpg" alt="Ganymede" />
                    </div>
                    <div className="team-content">
                      <h3 className="title-name">
                        <a href="#!">Ganymede</a>
                      </h3>
                      <div className="team-title">Marc-Antoine Barrois </div>
                      <div className="team-price">115 &euro; | 30 ml</div>
                    </div>
                  </div>
                </div>
                <div className="team-item">
                  <div className="team-inner-wrap">
                    <div className="images-wrap">
                      <img
                        src="/assets/images/products/2.jpg"
                        alt="Delina Exclusif"
                      />
                    </div>
                    <div className="team-content">
                      <h3 className="title-name">
                        <a href="#!">Delina Exclusif</a>
                      </h3>
                      <div className="team-title">Parfums de Marly</div>
                      <div className="team-price">160 &euro; | 30 ml</div>
                    </div>
                  </div>
                </div>
                <div className="team-item">
                  <div className="team-inner-wrap">
                    <div className="images-wrap">
                      <img src="/assets/images/products/3.jpg" alt="Paragon" />
                    </div>
                    <div className="team-content">
                      <h3 className="title-name">
                        <a href="#!">Paragon</a>
                      </h3>
                      <div className="team-title">Initio Parfums Prives</div>
                      <div className="team-price">270 &euro; | 90 ml</div>
                    </div>
                  </div>
                </div>
                <div className="team-item">
                  <div className="team-inner-wrap">
                    <div className="images-wrap">
                      <img
                        src="/assets/images/products/4.jpg"
                        alt="Acqua di Sale"
                      />
                    </div>
                    <div className="team-content">
                      <h3 className="title-name">
                        <a href="#!">Acqua di Sale</a>
                      </h3>
                      <div className="team-title">Profumum Roma</div>
                      <div className="team-price">265 &euro; | 100 ml</div>
                    </div>
                  </div>
                </div>
                <div className="team-item">
                  <div className="team-inner-wrap">
                    <div className="images-wrap">
                      <img
                        src="/assets/images/products/5.jpg"
                        alt="Jeroboam Gozo"
                      />
                    </div>
                    <div className="team-content">
                      <h3 className="title-name">
                        <a href="#!">Gozo</a>
                      </h3>
                      <div className="team-title">Jeroboam</div>
                      <div className="team-price"> 120 &euro; | 30 ml</div>
                    </div>
                  </div>
                </div>
                <div className="team-item">
                  <div className="team-inner-wrap">
                    <div className="images-wrap">
                      <img src="/assets/images/products/6.jpg" alt="Guéthary" />
                    </div>
                    <div className="team-content">
                      <h3 className="title-name">
                        <a href="#!">Guéthary</a>
                      </h3>
                      <div className="team-title">Eight &amp; Bob</div>
                      <div className="team-price"> 280 &euro; | 50 ml</div>
                    </div>
                  </div>
                </div>
                <div className="team-item">
                  <div className="team-inner-wrap">
                    <div className="images-wrap">
                      <img
                        src="/assets/images/products/7.jpg"
                        alt="1872 Masculine"
                      />
                    </div>
                    <div className="team-content">
                      <h3 className="title-name">
                        <a href="#!">1872 Masculine</a>
                      </h3>
                      <div className="team-title">Clive Christian</div>
                      <div className="team-price"> 350 &euro; | 50 ml</div>
                    </div>
                  </div>
                </div>
                <div className="team-item">
                  <div className="team-inner-wrap">
                    <div className="images-wrap">
                      <img src="/assets/images/products/8.jpg" alt="Megalium" />
                    </div>
                    <div className="team-content">
                      <h3 className="title-name">
                        <a href="#!">Megalium</a>
                      </h3>
                      <div className="team-title">Carner Barcelona</div>
                      <div className="team-price"> 170 &euro; | 100 ml</div>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          {/*<!-- Approach Section End -->*/}
          <div className="rs-services style1 bg1 pt-170 pb-60 md-pt-75 md-pb-80">
            <div className="container">
              <div className="sec-title text-center mb-60">
                <h2 className="title title2 title3 text-middle">
                  {t("home.approach.title")}
                </h2>
              </div>
              <div className="row y-middle">
                <div className="col-lg-4 col-md-6 md-mb-30">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <img
                          src="/assets/images/approach/1.png"
                          alt="Exclusive perfumes"
                        />
                      </div>
                      <div className="services-text">
                        <h4 className="title">
                          {t("home.approach.principle1.title")}
                        </h4>
                        <p className="services-txt">
                          {t("home.approach.principle1.desc1")}
                          <br />
                          <br />
                          {t("home.approach.principle1.desc2")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 md-mb-30">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <img
                          src="/assets/images/approach/2.png"
                          alt="Bespoke approach"
                        />
                      </div>
                      <div className="services-text">
                        <h4 className="title">
                          {" "}
                          {t("home.approach.principle2.title")}
                        </h4>
                        <p className="services-txt">
                          {t("home.approach.principle2.desc1")}
                          <br />
                          <br />
                          {t("home.approach.principle2.desc2")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="services-item">
                    <div className="services-wrap">
                      <div className="services-icon">
                        <img
                          src="/assets/images/approach/3.png"
                          alt="Immersive Luxury"
                        />
                      </div>
                      <div className="services-text">
                        <h4 className="title">
                          {t("home.approach.principle3.title")}
                        </h4>
                        <p className="services-txt">
                          {t("home.approach.principle3.desc1")}
                          <br />
                          <br />
                          {t("home.approach.principle3.desc2")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- Approach Section End -->*/}
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          {/* <!-- Our Brands Section Start --> */}
          <div
            id="rs-our-sponsor"
            className="rs-our-sponsor style1 home3-sponsor-style height-ctrl md-pb-70 md-pt-70"
          >
            <div className="container">
              <div className="sec-title text-center mb-60">
                <h2 className="title title2 title3 text-middle">
                  {t("home.brands.title")}
                </h2>
              </div>
              <div className="row">
                {brandsArray.map((brand) => (
                  <BrandsCard key={brand.title} brand={brand} />
                ))}
              </div>
            </div>
          </div>
          {/* <!-- Our Brands Section End --> */}
        </SwiperSlide>
        <SwiperSlide className="swiper-slide" key={render}>
          {/* <!-- News Section Start --> */}
          <div
            id="rs-blog"
            className="rs-blog blog-main-home home3-blog-style height-ctrl md-pb-70 md-pt-70"
          >
            <div className="container">
              <div className="sec-title text-center mb-60 md-mb-40">
                <h2 className="title title2 title3 text-middle">
                  {t("home.news.title")}
                </h2>
              </div>
              <OwlCarousel
                className="rs-carousel owl-carousel"
                loop={true}
                items={3}
                margin={30}
                autoplay={true}
                autoplayHoverPause={true}
                autoplayTimeout={5000}
                smartSpeed={800}
                dots={true}
                nav={false}
                navSpeed={false}
                center={false}
                navText={[
                  "<i class='flaticon-left-arrow'></i>",
                  "<i class='flaticon-right-arrow'></i>",
                ]}
                responsive={{
                  0: {
                    items: 1,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  768: {
                    items: 2,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  992: {
                    items: 2,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                  1200: {
                    items: 3,
                    nav: false,
                    dots: true,
                    center: false,
                  },
                }}
              >
                {/*<div className="blog-item">
                  <div className="image-wrap">
                    <Link to={`/${i18n.language}/news/black-friday`}>
                      <img src="/assets/images/blog/7.jpg" alt="Blog" />
                    </Link>
                    <div className="blog-date">
                      <span className="day">23</span>
                      <span className="month">Nov</span>
                    </div>
                  </div>
                  <div className="blog-content">
                    <ul className="blog-meta">
                      <li className="admin">
                        <i className="fa fa-user-o"></i>
                        Matija Žigić
                      </li>
                      <li className="cat-list">
                        <Link to={`/${i18n.language}/news/black-friday`}>
                          {" "}
                          <i className="fa fa-folder"></i>
                          {t("home.news.news")}
                        </Link>
                      </li>
                    </ul>
                    <h3 className="blog-title">
                      <Link to={`/${i18n.language}/news/black-friday`}>
                        {t("home.news.news7.title")}
                      </Link>
                    </h3>
                    <div className="blog-button">
                      <Link to={`/${i18n.language}/news/black-friday`}>
                        <span className="btn-txt">{t("home.read")}</span>
                        <i className="fa fa fa-chevron-right"></i>
                      </Link>
                    </div>
                  </div>
              </div>*/}
                {newsArray.map((news) => (
                  <LatestNewsCard key={news.title} news={news} />
                ))}
              </OwlCarousel>
            </div>
          </div>
          {/* <!-- Blog Section End --> */}
        </SwiperSlide>
        {/*<SwiperSlide className="swiper-slide">
          {/* <!-- Testimonials Section Start --> 
          <Testimonials />
          {/* <!-- Testimonials Section End -->  
        </SwiperSlide>*/}
        <SwiperSlide className="swiper-slide">
          {/* <!-- Contact Section Start --> */}
          <div className="rs-contact home-style1 home-style3 contact-home-style3 height-ctrl md-pb-70 md-pt-70">
            <div className="container">
              {/*<div className="sec-title text-center mb-60 md-mb-40">
                <h2 className="title title2 title3 text-middle">
                  {t("home.contact.title")}
                </h2>s
              </div>*/}
              <div className="row y-middle">
                <div className="col-lg-7 pr-80 md-pr-15">
                  <div className="contact-img md-mb-50">
                    <img
                      src="/assets/images/contact/visit-1.png"
                      alt="Visit us"
                    />
                  </div>
                </div>
                <div className="col-lg-5 pt-40">
                  <div className="sec-title">
                    <h2 className="title title2 title3 pb-30">
                      {t("home.contact.visit")}
                    </h2>
                  </div>
                  <div className="widget-item mb-20">
                    <div className="address-item">
                      <div className="address-icon">
                        <img
                          src="/assets/images/contact/icons/1.png"
                          alt="Images"
                        />
                      </div>
                      <div className="address-text">
                        {/*<h3 className="title">{t("home.contact.visit")}</h3>*/}
                        <h3 className="title">Podgorica</h3>
                        <p className="services-txt">
                          Bulevar Džordža Vašingtona 68, Podgorica
                          <br />
                          {t("footer.footerMain.workHours")}: 10.00 - 21.00
                          <br />
                          {t("footer.footerMain.sunday")}
                          <br />
                          <br />
                          Tel.:<a href="tel:+382 68 002 002">+382 68 002 002</a>
                          {/*<br /><br />
                          <Link 
                          to={translatedPaths["contact"]}
                          >
                          {t("home.contact.location")} &raquo;
                          </Link>*/}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="widget-item mb-20">
                    <div className="address-item">
                      <div className="address-icon">
                        <img
                          src="/assets/images/contact/icons/1.png"
                          alt="Images"
                        />
                      </div>
                      <div className="address-text">
                        {/*<h3 className="title">{t("home.contact.visit")}</h3>*/}
                        <h3 className="title">Porto Montenegro</h3>
                        <p className="services-txt">
                          Strada Navale, Porto Montenegro, Tivat
                          <br />
                          {t("footer.footerMain.workHours")}:09.00 - 23.00
                          <br />
                          {t("footer.footerMain.sunday")}
                          {/*<br /><br />
                          Tel.:<a href="tel:+382 68 004 004">+382 68 004 004</a>*/}
                          {/*<br /><br />
                          <Link 
                          to={translatedPaths["contact"]}
                          >
                          {t("home.contact.location")} &raquo;
                          </Link>*/}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*<div className="widget-item mb-20">
                    <div className="address-item">
                      <div className="address-icon">
                        <img
                          src="/assets/images/contact/icons/2.png"
                          alt="Email us"
                        />
                      </div>
                      <div className="address-text">
                        <h3 className="title">{t("home.contact.email")}</h3>
                        <p className="services-txt">
                          <a href="mailto:info@parfimerija-duro.me">
                            info@parfimerija-duro.me
                          </a>
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="widget-item">
                    <div className="address-item">
                      <div className="address-icon">
                        <img
                          src="/assets/images/contact/icons/3.png"
                          alt="Call us"
                        />
                      </div>
                      <div className="address-text">
                        <h3 className="title">{t("home.contact.call")}</h3>
                        <p className="services-txt">
                          <a href="tel:+382 68 002 002">+382 68 002 002</a>
                        </p>
                      </div>
                    </div>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Contact Section End -->   */}
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          {/* <!-- Contact Section Start --> */}
          <div className="rs-contact home-style1 home-style3 contact-home-style4 height-ctr mt-100 md-pb-50 md-pt-40">
            <div className="container">
              <div className="sec-title text-center mb-60 md-mb-40">
                <h2 className="title title2 title3 text-middle">
                  {t("home.contact.contact")}
                </h2>
              </div>
              {/* <!-- Contact Section Start --> */}
              <div className="rs-contact">
                <div className="row y-middle">
                  <div className="col-lg-6 pr-40 md-pr-15 md-mb-50">
                    <div className="contact-map">
                      <iframe
                        title="Location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.0839057260973!2d19.242565735848217!3d42.44210516371435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134deb7b349e4fe3%3A0x86dacff7773dcc05!2sDUR%C5%8C%20%7C%20Niche%20perfumery!5e0!3m2!1sen!2srs!4v1693316625728!5m2!1sen!2srs"
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="contact-wrap">
                      {/*<div className="sec-title">
                        <h2 className="title title2 title3 pb-30">
                        {t("home.contact.contact")}
                        </h2>
                      </div>*/}
                      <div id="form-messages"></div>
                      <ContactForm />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Contact Section End --> */}

              <div className="copyright text-center pt-25">
                <p>
                  {t("footer.footerHome.heartBefore")}{" "}
                  <i className="fa fa-heart"></i>{" "}
                  {t("footer.footerHome.heartAfter")}
                  <a href="https://intellect.se">Intellect</a>.
                </p>
              </div>
              {/*<div className="footer-logo text-center pt-10">
                <img src="/assets/images/duro_sign_light.svg" alt="DURŌ" />
                </div>*/}
            </div>
          </div>
          {/* <!-- Contact Section End -->  */}
        </SwiperSlide>
      </Swiper>
      <div className="swiper-pagination"></div>
    </div>
  );
};

export default SwiperDesktop;
