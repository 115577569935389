import React from "react";
import { useTranslation } from "react-i18next";
import newsData from "../data/news.json";
import { Link } from "react-router-dom";

export default function NewsWidget() {
  const { t, i18n } = useTranslation();
  const newsArray = Object.values(newsData);

  return (
    <div className="recent-posts mb-50">
      <div className="widget-title">
        <h3 className="title">{t("home.news.recent")}</h3>
      </div>
      {/*<div className="recent-post-widget no-border">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/black-friday`}>
            <img
              src={newsData["black-friday"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/black-friday`}>
            {t(newsData["black-friday"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["black-friday"].date}{" "}
          </span>
        </div>
      </div>*/}
      {/* <div className="recent-post-widget">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/new-brand-introducing-rosendo-mateu`}>
            <img
              src={newsData["new-brand-introducing-rosendo-mateu"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/new-brand-introducing-rosendo-mateu`}>
            {t(newsData["new-brand-introducing-rosendo-mateu"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["new-brand-introducing-rosendo-mateu"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/duro-porto-montenegro-reopening-2024`}>
            <img
              src={newsData["duro-porto-montenegro-reopening-2024"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/duro-porto-montenegro-reopening-2024`}>
            {t(newsData["duro-porto-montenegro-reopening-2024"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["duro-porto-montenegro-reopening-2024"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/new-brand-introducing-bdk-parfums`}>
            <img
              src={newsData["new-brand-introducing-bdk-parfums"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/new-brand-introducing-bdk-parfums`}>
            {t(newsData["new-brand-introducing-bdk-parfums"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["new-brand-introducing-bdk-parfums"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/new-brand-introducing-francesca-bianchi`}>
            <img
              src={newsData["new-brand-introducing-francesca-bianchi"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/new-brand-introducing-francesca-bianchi`}>
            {t(newsData["new-brand-introducing-francesca-bianchi"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["new-brand-introducing-francesca-bianchi"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/new-brand-introducing-venti4`}>
            <img
              src={newsData["new-brand-introducing-venti4"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/new-brand-introducing-venti4`}>
            {t(newsData["new-brand-introducing-venti4"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["new-brand-introducing-venti4"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/new-brand-introducing-lorenzo-pazzaglia`}>
            <img
              src={newsData["new-brand-introducing-lorenzo-pazzaglia"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/new-brand-introducing-lorenzo-pazzaglia`}>
            {t(newsData["new-brand-introducing-lorenzo-pazzaglia"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["new-brand-introducing-lorenzo-pazzaglia"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/porto-montenegro-perfumery-temporary-closure-notice`}>
            <img
              src={newsData["porto-montenegro-perfumery-temporary-closure-notice"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/porto-montenegro-perfumery-temporary-closure-notice`}>
            {t(newsData["porto-montenegro-perfumery-temporary-closure-notice"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["porto-montenegro-perfumery-temporary-closure-notice"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/world-premiere-the-new-perfume-althair`}>
            <img
              src={newsData["world-premiere-the-new-perfume-althair"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/world-premiere-the-new-perfume-althair`}>
            {t(newsData["world-premiere-the-new-perfume-althair"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["world-premiere-the-new-perfume-althair"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget no-border">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/new-brand-introducing-maison-crivelli`}>
            <img
              src={newsData["new-brand-introducing-maison-crivelli"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/new-brand-introducing-maison-crivelli`}>
            {t(newsData["new-brand-introducing-maison-crivelli"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["new-brand-introducing-maison-crivelli"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/duro-porto-montenegro-five-star-perfumery`}>
            <img
              src={
                newsData[
                  "duro-porto-montenegro-five-star-perfumery"
                ].img
              }
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/duro-porto-montenegro-five-star-perfumery`}>
            {" "}
            {t(newsData["duro-porto-montenegro-five-star-perfumery"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["duro-porto-montenegro-five-star-perfumery"].date}{" "}
          </span>
        </div>
      </div>
      <div className="recent-post-widget">
        <div className="post-img">
          <Link to={`/${i18n.language}/news/our-new-chapter-the-world-of-duro-perfumeries`}>
            <img
              src={newsData["our-new-chapter-the-world-of-duro-perfumeries"].img}
              alt=""
            />
          </Link>
        </div>
        <div className="post-desc">
          <Link to={`/${i18n.language}/news/our-new-chapter-the-world-of-duro-perfumeries`}>
            {t(newsData["our-new-chapter-the-world-of-duro-perfumeries"].title)}
          </Link>
          <span className="date-post">
            {" "}
            <i className="fa fa-calendar"></i> {newsData["our-new-chapter-the-world-of-duro-perfumeries"].date}{" "}
          </span>
        </div>
      </div> */}

      {newsArray.map((news, i) => {
        const path =
          i18n?.language === "en"
            ? `/en/news/${news.path}`
            : news?.me_path
            ? `/me/vijesti/${news.me_path}`
            : `/me/vijesti/${news.path}`;

        return (
          <div className="recent-post-widget">
            <div className="post-img">
              <Link to={path}>
                <img src={news.img} alt="" />
              </Link>
            </div>
            <div className="post-desc">
              <Link to={path}>{t(news.title)}</Link>
              <span className="date-post">
                {" "}
                <i className="fa fa-calendar"></i> {news.date}{" "}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
