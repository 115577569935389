import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Link, useSearchParams } from "react-router-dom";
import Cookie from "js-cookie";
import { getTranslatedPath } from "../utils/languageUtils";
import { CartIcon } from "../utils/icons";

export default function Header2({ header, handleLanguageChange,setIsVisible }) {
  const { t, i18n } = useTranslation();
  // const [language, setLanguage] = useState(i18n.language);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [active, setActive] = useState("");
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  let trans = searchParams.get("trans");
  const handleMenu = () => {
    setIsMenuOpen((prevMenu) => !prevMenu);
  };

  const handleMobileMenu = () => {
    window.document
      .getElementsByClassName("full-width-header")[0]
      .classList.toggle("nav-expanded");
  };

  useEffect(() => {
    const handleNavbar = () => {
      if (
        location.pathname.includes("story") ||
        location.pathname.includes("o-nama")
      ) {
        setActive("story");
      } else if (
        location.pathname.includes("brands") ||
        location.pathname.includes("brendovi")
      ) {
        setActive("brands");
      } else if (
        location.pathname.includes("news") ||
        location.pathname.includes("vijesti")
      ) {
        setActive("news");
      } else if (
        location.pathname.includes("products") ||
        location.pathname.includes("proizvodi")
      ) {
        setActive("products");
      } else if (
        location.pathname.includes("contact") ||
        location.pathname.includes("kontakt")
      ) {
        setActive("contact");
      } else if (
        location.pathname.includes("perfumeries") ||
        location.pathname.includes("parfimerije")
      ) {
        setActive("locations");
      }
    };

    handleNavbar();
    setIsMenuOpen(false);
  }, [location]);

  useEffect(() => {
    Cookie.set("language", i18n.language);
  }, [i18n.language]);

  const translatedPaths = getTranslatedPath(i18n.language);

  return (
    <>
      {/* <!--Full width header Start--> */}
      <div className="full-width-header">
        {/* <!--Header Start--> */}
        <div className="offwrap" onClick={handleMobileMenu}></div>

        <header
          id="rs-header"
          className="rs-header header-style3 header-transparent"
        >
          {/* <!-- Menu Start --> */}
          <div
            className={
              header ? "menu-area menu-sticky sticky" : "menu-area menu-sticky"
            }
          >
            <div className="container">
              <div className="row-table">
                <div className="col-cell header-logo">
                  <div className="logo-area">
                    <Link to={`/${i18n.language}/`}>
                      <img
                        className="normal-logo"
                        src="/assets/images/logo-light.svg"
                        alt="logo"
                      />
                      <img
                        className="sticky-logo"
                        src="/assets/images/logo-dark.svg"
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
                <div
                  className={
                    isMenuOpen
                      ? "col-cell menu-responsive"
                      : "col-cell menu-responsive active__main__menu"
                  }
                >
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <nav className="rs-menu hidden-md">
                        <ul className="nav-menu">
                          <li>
                            <Link to={`/${i18n.language}/`}>
                              {t("header.home.menuTitle")}
                            </Link>
                          </li>
                          <li
                            className={
                              active === "story" ? "current-menu-item" : ""
                            }
                          >
                            <Link to={translatedPaths["our-story"]}>
                              {t("header.story.menuTitle")}
                            </Link>
                          </li>
                          <li
                            // onClick={() => handleActiveLink("locations")}
                            className={
                              active === "locations"
                                ? "current-menu-item menu-item-has-children"
                                : "menu-item-has-children"
                            }
                          >
                            <Link to={translatedPaths["perfumeries"]}>
                              {t("header.locations.menuTitle")}
                            </Link>
                            <ul className="sub-menu">
                              <li
                              // onClick={() => handleActiveLink("podgorica")}
                              >
                                <Link
                                  to={`/${i18n.language}/${
                                    i18n.language === "en"
                                      ? "perfumeries"
                                      : "parfimerije"
                                  }/podgorica`}
                                >
                                  Podgorica
                                </Link>
                              </li>
                              <li
                              // onClick={() =>
                              //   handleActiveLink("porto-montenegro")
                              // }
                              >
                                <Link
                                  to={`/${i18n.language}/${
                                    i18n.language === "en"
                                      ? "perfumeries"
                                      : "parfimerije"
                                  }/porto-montenegro`}
                                >
                                  Porto Montenegro
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li
                            className={
                              active === "brands" ? "current-menu-item" : ""
                            }
                          >
                            <Link to={translatedPaths["brands"]}>
                              {t("header.brands.menuTitle")}
                            </Link>
                          </li>
                          <li
                            className={
                              active === "products" ? "current-menu-item" : ""
                            }
                          >
                            <Link to={translatedPaths["products"]}>
                              {t("header.products.menuTitle")}
                            </Link>
                          </li>
                          <li
                            className={
                              active === "news" ? "current-menu-item" : ""
                            }
                          >
                            <Link to={translatedPaths["news"]}>
                              {t("header.press.menuTitle")}
                            </Link>
                          </li>
                          <li
                            className={
                              active === "contact" ? "current-menu-item" : ""
                            }
                          >
                            <Link to={translatedPaths["contact"]}>
                              {t("header.contact.menuTitle")}
                            </Link>
                          </li>
                        </ul>
                        {/* <!-- //.nav-menu --> */}
                      </nav>
                    </div>
                    {/* <!-- //.main-menu --> */}
                  </div>
                </div>
                <div className="col-cell">
                  <div className="expand-btn-inner">
                    <div
                      id="rs_click__id"
                      className={
                        isMenuOpen
                          ? "rs_click_menu rs__open_hum"
                          : "rs_click_menu"
                      }
                      onClick={handleMenu}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>

                    <div className="main-menu">
                      <nav className="rs-menu hidden-md">
                        <ul className="nav-menu language-menu">
                          <li onClick={handleLanguageChange}>
                            <Link to="#" className="language-btn">
                              {t("header.language")}
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>

                    <ul>
                      <li className="humburger">
                        <a
                          id="nav-expander"
                          className="nav-expander bar"
                          href="#!"
                          onClick={handleMobileMenu}
                        >
                          <div className="bar">
                            <span className="dot1"></span>
                            <span className="dot2"></span>
                            <span className="dot3"></span>
                            <span className="dot4"></span>
                            <span className="dot5"></span>
                            <span className="dot6"></span>
                            <span className="dot7"></span>
                            <span className="dot8"></span>
                            <span className="dot9"></span>
                          </div>
                        </a>
                      </li>

                      <div className="language-menu-mobile">
                        <div onClick={handleLanguageChange}>
                          <Link to="#" className="language-btn-mobile">
                            {t("header.language")}
                          </Link>
                        </div>
                      </div>

                        <div
                        // onClick={() => {
                        //   const newLanguage = language === "me" ? "en" : "me";
                        //   setLanguage(newLanguage);
                        //   i18n.changeLanguage(newLanguage);
                        // }}
                        >
                          <Link
                            to="https://shop.parfimerija-duro.me/me/cart"
                            className="custom-margin"
                          >
                            <CartIcon header={header} />
                            {trans && (
                              <span
                                className="badge rounded-circle bg-danger text-danger top-0 start-100 translate-middle cart-badge"
                                style={{ fontSize: "10px" }}
                              >
                                {"1"}
                              </span>
                            )}
                          </Link>
                      </div>
                      <button onClick={() => setIsVisible(true)} className="bg-transparent mx-md-3" style={{border: 'none',scale: '1.15'}}>
                        <i className={`fa-solid fa-magnifying-glass ${!header && 'text-white'}`}></i>
                      </button>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Menu End --> */}

          {/* <!-- Canvas Mobile Menu start --> */}
          <nav
            className="right_menu_togle mobile-navbar-menu"
            id="mobile-navbar-menu"
          >
            <div className="close-btn">
              <a
                id="nav-close2"
                className="nav-close"
                href="#!"
                onClick={handleMobileMenu}
              >
                <div className="line">
                  <span className="line1"></span>
                  <span className="line2"></span>
                </div>
              </a>
            </div>
            <div className="canvas-logo">
              <Link to={`/${i18n.language}/`}>
                <img src="/assets/images/duro_sign_light.svg" alt="DURŌ" />
              </Link>
            </div>
            <ul className="nav-menu">
              <li onClick={handleMobileMenu}>
                <Link to={`/${i18n.language}/`}>
                  {t("header.home.menuTitle")}
                </Link>
              </li>
              <li onClick={handleMobileMenu}>
                <Link to={translatedPaths["our-story"]}>
                  {t("header.story.menuTitle")}
                </Link>
              </li>
              <li onClick={handleMobileMenu}>
                <Link to={translatedPaths["perfumeries"]}>
                  {t("header.locations.menuTitle")}
                </Link>
                <ul className="sub-menu">
                  <li
                  // onClick={() => handleActiveLink("podgorica")}
                  >
                    <Link
                      to={`/${i18n.language}/${
                        i18n.language === "en" ? "perfumeries" : "parfimerije"
                      }/podgorica`}
                    >
                      Podgorica
                    </Link>
                  </li>
                  <li
                  // onClick={() =>
                  //   handleActiveLink("porto-montenegro")
                  // }
                  >
                    <Link
                      to={`/${i18n.language}/${
                        i18n.language === "en" ? "perfumeries" : "parfimerije"
                      }/porto-montenegro`}
                    >
                      Porto Montenegro
                    </Link>
                  </li>
                </ul>
              </li>
              <li onClick={handleMobileMenu}>
                <Link to={translatedPaths["brands"]}>
                  {t("header.brands.menuTitle")}
                </Link>
              </li>
              <li onClick={handleMobileMenu}>
                <Link to={translatedPaths["news"]}>
                  {t("header.press.menuTitle")}
                </Link>
              </li>
              <li onClick={handleMobileMenu}>
                <Link to={translatedPaths["contact"]}>
                  {t("header.contact.menuTitle")}
                </Link>
              </li>
            </ul>
            {/* <!-- //.nav-menu -->  */}
            <div className="canvas-contact">
              <div className="address-area">
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-location"></i>
                  </div>
                  {/*<div className="info-content">
                    <h4 className="title">{t("header.address")}</h4>
                    <em>
                      TC "Galerija" (entrance 3.)
                      <br />
                      Bulevar Vudroa Vilsona 14
                      <br />
                      11000 Belgrade
                      <br />
                      Serbia
                    </em>
                    </div>*/}
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-email"></i>
                  </div>
                  <div className="info-content">
                    <h4 className="title">{t("header.email")}</h4>
                    <em>
                      <a href="mailto:info@parfimerija-duro.me">
                        info@parfimerija-duro.me
                      </a>
                    </em>
                  </div>
                </div>
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-call"></i>
                  </div>
                  <div className="info-content">
                    <h4 className="title">{t("header.phone")}</h4>
                    <em>
                      <a href="tel:+382 68 002 002">+382 68 002 002</a>
                    </em>
                  </div>
                </div>
              </div>
              <ul className="social">
                <li>
                  <a href="https://www.facebook.com/duro.montenegro">
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/DuroCologne">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/duro_montenegro">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@duro_montenegro">
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@duro_montenegro">
                    <i className="fa-brands fa-tiktok"></i>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          {/* <!-- Canvas Menu end -->    */}
        </header>
        {/* <!--Header End--> */}
      </div>
      {/* <!--Full width header End--> */}
    </>
  );
}
