import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const Breadcrumbs = ({ bg }) => {
  const location = useLocation();
  // Split the pathname and filter out the locale part
  const pathnames = location.pathname.split('/').filter((x) => x && !['en', 'me'].includes(x));

  return (
    <div className={`${bg === 'light' ? 'gray-bg4' : 'lion-bg'} py-4`}>
      {pathnames.length > 0 && (
        <nav className='container'>
          <ul className={`${bg === 'light' ? 'breadcrumbs-list-light' : 'breadcrumbs-list'} breadcrumbs-flex gap-3`}>
            <li>
              <Link to={`/${location.pathname.split('/')[1]}`}><i className='fa-solid fa-house'></i></Link>
            </li>
            {pathnames.map((pathname, index) => {
              const route = `/${location.pathname.split('/')[1]}/${pathnames.slice(0, index + 1).join('/')}`;
              const isLast = index === pathnames.length - 1;

              return (
                <React.Fragment key={route}>
                  <li>
                    <i className='fa-solid fa-chevron-right breadcrumbs-separator'></i>
                  </li>
                  <li>
                    {isLast ? (
                      <span className='capitalize breadcrumbs-active'>{pathname}</span>
                    ) : (
                      <Link to={route} className='capitalize'>
                        {pathname}
                      </Link>
                    )}
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Breadcrumbs;
